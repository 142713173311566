import React, { useCallback } from 'react';

import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import Spinner from '../../Spinner';
import { useCreateFolderMutation } from './api';
import { ITypeFolder } from './interface';
import Swal from 'sweetalert2';

interface IProps {
  handleClose: () => void;
}

const Create: React.FC<IProps> = ({ handleClose }) => {
  const [create, { isLoading }] = useCreateFolderMutation();

  const submit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formElements = e.currentTarget.elements;

      const name = (formElements.namedItem('name') as HTMLInputElement).value;
      const type = (formElements.namedItem('type') as HTMLSelectElement).value;

      Swal.fire({
        title: 'Êtes-vous sûr ?',
        text: `Vous êtes sur le point de créer une nouveau dossier nommé : ${name}`,
        icon: 'question',
        cancelButtonText: 'Annuler',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          create({ name, type: Number(type) as ITypeFolder })
            .then(() => {
              handleClose();
              Swal.fire({ icon: 'success', title: 'Création', text: 'Le dossier à bien été créer' });
            })
            .catch(() => Swal.fire({ icon: 'error', title: 'Création', text: 'Erreur lors de la création' }));
        }
      });
    },
    [create, handleClose],
  );

  return (
    <Modal show={true} onHide={handleClose}>
      {isLoading ? <Spinner /> : null}
      <Modal.Header closeButton>
        <Modal.Title>Création d'un nouveau dossier</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submit}>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Label htmlFor="name">Nom du dossier</Form.Label>
              <Form.Control aria-describedby="name" name="name" placeholder="Entrer un nom" required />
            </Col>
            <Col md={12} className="mt-3">
              <Form.Select name="type" required>
                <option value={ITypeFolder.Private} selected>
                  Private
                </option>
                <option value={ITypeFolder.Public}>Public</option>
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit">Valider</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Create;
