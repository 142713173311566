import type { IDeleteRequest, IFileRequest, IFileResponse, IMoveFileRequest, IMp4Request, IZipRequest } from './types';
import { api } from '../../api';

export const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    GetFiles: builder.query<IFileResponse, IFileRequest>({
      query: (params) => ({
        url: 'files',
        params,
      }),
      providesTags: ['files'],
    }),
    MoveFile: builder.mutation<unknown, IMoveFileRequest>({
      query: (body) => ({
        url: 'files/move',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['files'],
    }),
    DeleteFile: builder.mutation<unknown, IDeleteRequest>({
      query: (body) => ({
        url: 'files/delete',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['files'],
    }),
    ZipFile: builder.mutation<unknown, IZipRequest>({
      query: (body) => ({
        url: 'files/zip',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['files'],
    }),
    Mp4File: builder.mutation<unknown, IMp4Request>({
      query: (body) => ({
        url: 'files/convert/mp4',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['files'],
    }),
  }),
});

export const { useGetFilesQuery, useMoveFileMutation, useDeleteFileMutation, useMp4FileMutation, useZipFileMutation } = filesApi;
