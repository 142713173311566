import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import { IMessage } from './interface';

interface IState {
  messages: IMessage[];
  count: number;
  users: number;
  lastMessage: IMessage | null;
  status: number;
}

const initialState: IState = {
  messages: [],
  count: 0,
  users: 0,
  lastMessage: null,
  status: 0,
};

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    addMessage(state, action: PayloadAction<IMessage>) {
      state.messages = [...state.messages, action.payload];
      if (state.messages.length > 50) state.messages.pop();
      state.count = state.count + 1;
      state.lastMessage = action.payload;
    },
    clearCounter(state) {
      state.count = 0;
    },
    incrementUsers(state) {
      state.users = state.users + 1;
    },
    decrementUsers(state) {
      state.users = state.users - 1;
    },
    initUsers(state, action: PayloadAction<number>) {
      state.users = action.payload;
    },
    setStatus(state, action: PayloadAction<number>) {
      state.status = action.payload;
    },
    clear(state) {
      state.messages = [];
      state.count = 0;
      state.lastMessage = null;
    },
  },
});

export const { addMessage, clear, clearCounter, incrementUsers, decrementUsers, initUsers, setStatus } = socketSlice.actions;

export const name = socketSlice.name;

export const selectSocket = (state: RootState) => state.socket;

export default socketSlice.reducer;
