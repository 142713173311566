import type { IAuthResponse, IAuthForm } from './types';
import { api } from '../../api';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    Login: builder.mutation<IAuthResponse, IAuthForm>({
      query: (body) => ({
        url: 'login',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['files', 'folders'],
    }),
  }),
});

export const { useLoginMutation } = authApi;
