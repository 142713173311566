import { createSlice, isRejected } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import { logout } from '../auth/authSlice';

const initialState = {
  idFile: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIDFile(state, action: PayloadAction<number>) {
      state.idFile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.idFile = 0;
    });
    builder.addMatcher(isRejected, (state) => {
      state.idFile = 0;
    });
  },
});

export const { setIDFile } = userSlice.actions;

export const name = userSlice.name;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
