import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://api.nyugame.fr/',
  // baseUrl: 'http://localhost:3001/',
  timeout: 50000,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    if (token) headers.set('Authorization', `Bearer ${token}`);

    return headers;
  },
});

export const urlSocket = 'wss://socket.nyugame.fr/msg';
// export const urlSocket = 'ws://localhost:3002/msg';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: ['files', 'folders'],
  endpoints: () => ({}),
});
