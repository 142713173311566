import React, { useLayoutEffect } from 'react';
import Container from '../../template/Container';
import { Card, Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';

interface IProps {
  url?: string;
}

const Streaming: React.FC<IProps> = ({ url }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useLayoutEffect(() => {
    if (!state) navigate('/home');

    console.log(state);
  }, [state, navigate]);

  return (
    <Container title="Visualisation">
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title>Visualisation</Card.Title>
            </Card.Header>
            <Card.Body>
              <ReactPlayer url={state.url} controls style={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Streaming;
