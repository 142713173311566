import { ICreateFolderRequest, IFolder } from './interface';
import { api } from '../../api';

export const foldersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    GetFolders: builder.query<IFolder[], any>({
      query: () => 'folders',
      providesTags: ['folders'],
    }),
    createFolder: builder.mutation<IFolder[], ICreateFolderRequest>({
      query: (body) => ({
        url: 'folders',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['folders'],
    }),
  }),
});

export const { useGetFoldersQuery, useCreateFolderMutation } = foldersApi;
