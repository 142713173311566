import { attachment, streaming } from './config';
import { IFile } from './types';

export const addAttachment = (url: string): string => {
  if (url.indexOf('download.nyugame.fr') !== -1) {
    return url + '?api_key=' + attachment;
  }

  if (url.indexOf('streaming.nyugame.fr') !== -1) {
    return url + '?api_key=' + streaming;
  }

  return url;
};

export const findFileByName = (files: IFile[], name: string) => files.find((x) => x.name === name);
