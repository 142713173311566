import { useCallback, useEffect, useState } from 'react';

const useResize = (initialSize: number, minus: number = 0) => {
  const [minHeight, setMinHeight] = useState(initialSize);

  const resize = useCallback(() => {
    setMinHeight(window.innerHeight - minus);
  }, [minus]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [minHeight];
};

export default useResize;
