import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="main-footer">
      <strong className="ms-3">Copyright © 2023 | NyuFolder</strong>
    </footer>
  );
};

export default Footer;
