import React, { useEffect, useState } from 'react';

import { Card, Col, Form, Row } from 'react-bootstrap';
import Container from '../../template/Container';
import { useDispatch, useSelector } from 'react-redux';
import { clearCounter, selectSocket } from './socketSlice';
import { convertDate } from '../../helpers';
import { AppDispatch } from '../../store';
import { ILevel } from './interface';

import DataTable, { Alignment } from 'react-data-table-component';

const Console: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { messages } = useSelector(selectSocket);
  const [key, setKey] = useState<ILevel | ''>('');

  useEffect(() => {
    dispatch(clearCounter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container title="Loggeur">
      <Row>
        <Col md={12}>
          <Card className="card-default">
            <Card.Header>
              <Card.Title>Console</Card.Title>
            </Card.Header>
            <Card.Body>
              <DataTable
                data={messages.filter((x) => {
                  if (key !== '') {
                    return x.Key === key;
                  }

                  return false;
                })}
                fixedHeader
                responsive
                fixedHeaderScrollHeight="60vh"
                subHeader
                subHeaderAlign={Alignment.LEFT}
                subHeaderComponent={
                  <Col lg={4} sm={6} xs={8} className="d-flex justify-content-center align-items-center">
                    <Form.Select onChange={(e) => setKey(e.target.value as ILevel | '')}>
                      <option>-- Action --</option>
                      <option value={ILevel.Debug}>{ILevel.Debug}</option>
                      <option value={ILevel.Info}>{ILevel.Info}</option>
                      <option value={ILevel.Error}>{ILevel.Error}</option>
                    </Form.Select>
                  </Col>
                }
                columns={[
                  { name: 'Action', selector: (row) => row.Action },
                  { name: 'Message', selector: (row) => row.Message },
                  { name: 'Key', selector: (row) => row.Key },
                  { name: 'Created', selector: (row) => convertDate(row.Created) },
                ]}
                pagination
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Console;
