import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faCogs, faSignOutAlt, faExchange, faUser } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { logout } from '../features/auth/authSlice';
import { Link } from 'react-router-dom';
import { selectSocket } from '../features/socket/socketSlice';

const Header: React.FC = () => {
  const [search, setSearch] = useState('');
  const [expend, setExpend] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const container = document.getElementById('container');
    const body = document.getElementById('body');

    if (container && body) {
      container.onclick = () => {
        setExpend(false);
        if (body.classList.contains('sidebar-open')) {
          body.classList.remove('sidebar-open');
          body.classList.add('sidebar-closed');
        }
      };
    }
  }, []);

  const pushMenuMobile = useCallback(() => {
    const body = document.getElementById('body');
    if (body) {
      if (body.classList.contains('sidebar-closed')) {
        body.classList.remove('sidebar-closed');
        body.classList.add('sidebar-open');
      } else {
        body.classList.remove('sidebar-open');
        body.classList.add('sidebar-closed');
      }
    }
  }, []);

  const pushMenu = useCallback(() => {
    const body = document.getElementById('body');
    if (body) {
      pushMenuMobile();
      if (body.classList.contains('sidebar-collapse')) {
        body.classList.remove('sidebar-collapse');
        return;
      }
      body.classList.add('sidebar-collapse');
    }
  }, [pushMenuMobile]);

  return (
    <nav className="main-header navbar navbar-expand navbar-dark navbar-gray">
      <ul className="navbar-nav">
        <li className="nav-item" role="button" onClick={pushMenu}>
          <div className="nav-link">
            <FontAwesomeIcon icon={faBars} />
          </div>
        </li>
      </ul>
      <Form className="form-inline ml-3">
        <InputGroup size="sm">
          <FormControl type="text" className="form-control-navbar" placeholder="Rechercher..." value={search} onChange={(e) => setSearch(e.target.value)} />
          <div className="input-group-append">
            <Button className="btn-navbar">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </div>
        </InputGroup>
      </Form>

      <ul className="navbar-nav ml-auto">
        <Console />
        <CountUser />
        <li className={`nav-item dropdown${expend && ' show'}`}>
          <div className="nav-link" data-toggle="dropdown" role="button" aria-expanded={expend} onClick={() => setExpend(!expend)}>
            <FontAwesomeIcon icon={faCogs} />
          </div>
          <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-right${expend && ' show'}`}>
            {/* <a href="https://seat.nyugame.fr/profile/settings" className="dropdown-item">
              <i className="fas fa-id-card"></i> Profil
            </a>
            <div className="dropdown-divider"></div> */}

            <Button type="submit" className="btn btn-link dropdown-item" onClick={() => dispatch(logout())}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              Se déconnecter
            </Button>
          </div>
        </li>
      </ul>
    </nav>
  );
};

const Console: React.FC = () => {
  const { count, lastMessage, status } = useSelector(selectSocket);

  const statusWebsocket = useMemo(() => {
    switch (status) {
      case WebSocket.CONNECTING:
        return 'warning';
      case WebSocket.CLOSED:
        return 'danger';
      case WebSocket.OPEN:
        return 'success';
      default:
        return 'info';
    }
  }, [status]);

  return (
    <>
      <li className="nav-item dropdown d-flex align-items-center small">{lastMessage?.Message}</li>
      <li className="nav-item">
        <Link to={'/console'} className="nav-link" role="button">
          <FontAwesomeIcon icon={faExchange} />
          <span className={`badge badge-${statusWebsocket} navbar-badge`}>{count}</span>
        </Link>
      </li>
    </>
  );
};

const CountUser: React.FC = () => {
  const { users } = useSelector(selectSocket);

  return (
    <li className="nav-item">
      <Link to={'/console'} className="nav-link" role="button">
        <FontAwesomeIcon icon={faUser} />
        <span className="badge badge-info navbar-badge">{users}</span>
      </Link>
    </li>
  );
};

export default Header;
