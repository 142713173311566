// socketMiddleware.ts
import { Middleware, createAction } from '@reduxjs/toolkit';
import { addMessage, decrementUsers, incrementUsers, initUsers, setStatus } from './features/socket/socketSlice';
import { IAction, IMessage } from './features/socket/interface';
import { toast } from 'react-toastify';

const WS_CONNECT = 'WS_CONNECT';
const WS_SEND = 'WS_SEND';
const WS_DISCONNECT = 'WS_DISCONNECT';

export const wsconnect = createAction<{ url: string }>(WS_CONNECT);
export const wsSend = createAction<IMessage>(WS_SEND);
export const wsDisconnect = createAction(WS_DISCONNECT);

interface WSConnectAction {
  type: typeof WS_CONNECT;
  payload: { url: string };
}

interface WSSendAction {
  type: typeof WS_SEND;
  payload: IMessage;
}

type WSAction = WSConnectAction | WSSendAction | { type: typeof WS_DISCONNECT };

let socket: WebSocket | null = null;
const createSocket = (url: string) => new WebSocket(url);

const socketMiddleware: Middleware<{}, any, any> = (storeAPI) => (next) => (action: WSAction) => {
  const onOpen = (store: typeof storeAPI) => (event: Event) => {
    console.log('WebSocket open');
    store.dispatch(setStatus(socket ? socket.readyState : WebSocket.CONNECTING));
  };

  const onClose = (store: typeof storeAPI) => (event: CloseEvent) => {
    console.log('WebSocket closed');
    store.dispatch(setStatus(socket ? socket.readyState : WebSocket.CLOSED));
  };

  const onMessage = (store: typeof storeAPI) => (event: MessageEvent) => {
    var messages = event.data.split('\n');
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      try {
        const parse = JSON.parse(message) as IMessage;

        if (parse.Action === IAction.Notif)
          toast(parse.Message, {
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
          });
        if (parse.Action === IAction.Logger || parse.Action === IAction.Notif) store.dispatch(wsSend(parse));
        if (parse.Action === IAction.Counter) {
          if (parse.Key === 'init') store.dispatch(initUsers(Number(parse.Message)));
          if (parse.Key === 'register') store.dispatch(incrementUsers());
          if (parse.Key === 'unregister') store.dispatch(decrementUsers());
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  switch (action.type) {
    case WS_CONNECT:
      if (socket !== null) socket.close();

      socket = createSocket(action.payload.url);
      socket.onmessage = onMessage(storeAPI);
      socket.onclose = onClose(storeAPI);
      socket.onopen = onOpen(storeAPI);
      break;
    case WS_DISCONNECT:
      if (socket !== null) socket.close();
      socket = null;
      break;
    case WS_SEND:
      storeAPI.dispatch(addMessage(action.payload));
      break;
    default:
      return next(action);
  }
};

export default socketMiddleware;
