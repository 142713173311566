import jwt_decode from 'jwt-decode';

export const convertTimestamp = (timestamp: number) => new Date(timestamp).toLocaleString();

export const convertDate = (date: string) => new Date(date).toLocaleString();

export function humanReadableBytes(bytes: number): string {
  if (bytes <= 0) return '0 octets';

  const units = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
  let power = Math.floor(Math.log2(bytes) / 10); // Calculer la puissance de 2 du nombre d'octets
  if (power > 8) power = 8; // Si la puissance dépasse la longueur du tableau units, limiter à la longueur du tableau
  const size = bytes / Math.pow(2, power * 10); // Convertir les octets en unité appropriée
  return `${size.toFixed(2)} ${units[power]}`; // Renvoyer la taille formatée avec l'unité appropriée
}

export function decodeJwt<T>(token: string): T | null {
  try {
    const decoded: T = jwt_decode(token);
    return decoded;
  } catch (error) {
    console.error('Erreur lors du décodage du token:', error);
    return null;
  }
}
