import React from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import { useGetFoldersQuery } from '../../folders/api';
import Spinner from '../../../Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  currentIDFolder: number;
}

const Move: React.FC<IProps> = ({ currentIDFolder }) => {
  const { data, isLoading, isFetching } = useGetFoldersQuery(null);

  return (
    <Row>
      <Form.Control name="type" value="move" type="hidden" />
      {isLoading || isFetching ? <Spinner /> : null}
      <Row>
        <Col md={5}>
          <Form.Select required name="from">
            {data &&
              data.map((x, k) => {
                if (x.id === currentIDFolder) {
                  return (
                    <option key={k} value={x.id} defaultValue={x.id}>
                      {x.name}
                    </option>
                  );
                }

                return null;
              })}
          </Form.Select>
        </Col>
        <Col md={2} className="d-flex justify-content-center align-items-center">
          <FontAwesomeIcon icon={faArrowRight} />
        </Col>
        <Col md={5}>
          <Form.Select required name="to">
            {data &&
              data.map((x, k) => (
                <option key={k} value={x.id} disabled={currentIDFolder === x.id}>
                  {x.name}
                </option>
              ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form.Check label={`Faire une copie ?`} name="copy" />
        </Col>
      </Row>
    </Row>
  );
};

export default Move;
