import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Alert, Form, Button, Spinner } from 'react-bootstrap';
import { useLoginMutation } from './api';
import { setToken } from './authSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';

const Auth: React.FC = () => {
  const [login, { isLoading }] = useLoginMutation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      try {
        const payload = await login({ username, password }).unwrap();
        dispatch(setToken(payload.token));
        setError('');
      } catch (err) {
        console.log(err);
        setError('Failed to log in. Please try again.');
      }
    },
    [dispatch, username, password, login],
  );

  useLayoutEffect(() => {
    if (username || password) {
      setError('');
    }
  }, [username, password]);

  useEffect(() => {
    return () => {
      setError('');
    };
  }, []);

  return (
    <div className="login-box">
      <div className="login-logo">
        <b>N</b>yu<b>F</b>older | Se connecter
      </div>
      <hr />
      <div className="login-box-body">
        <p className="login-box-msg">{error && <Alert variant="danger">{error}</Alert>}</p>
        <div className="box-body text-center">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Enter username" value={username} onChange={(e) => setUsername(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </Form.Group>

            <Form.Group className="mt-3 text-center">
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
              </Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};

/*

<div className="d-flex justify-content-center align-items-center vh-100">
      <Card style={{ width: '18rem' }}>
        <Card.Header>
          <Card.Title>Login</Card.Title>
        </Card.Header>
        
      </Card>
    </div>


*/

export default Auth;
