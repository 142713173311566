import React from 'react';
import { IFileFiltered } from './types';
import DataTable, { Alignment } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons';
import { addAttachment } from './helpers';

interface IProps {
  files: IFileFiltered[];
  isBusy: boolean;
  onClickFile?: (row: IFileFiltered) => void;
  onClickFolder?: (filename: string) => void;
  headerComponent: React.ReactNode;
}

const Table: React.FC<IProps> = ({ files = [], isBusy, onClickFile, onClickFolder, headerComponent }) => {
  return (
    <DataTable
      data={files}
      progressPending={isBusy}
      fixedHeader
      subHeader
      subHeaderAlign={Alignment.LEFT}
      subHeaderComponent={headerComponent}
      responsive
      fixedHeaderScrollHeight="60vh"
      onRowClicked={(row) => {
        if (!row.folder && onClickFile) return onClickFile(files[row.index]);
        onClickFolder && onClickFolder(row.name);
      }}
      columns={[
        {
          name: 'Folder',
          cell: (row) => (row.folder ? <FontAwesomeIcon icon={faFolder} /> : <FontAwesomeIcon icon={faFile} />),
        },
        { name: 'name', selector: (row) => row.name, sortable: true },
        { name: 'size', selector: (row) => row.size, sortable: true },
        {
          name: 'url',
          cell: (row) =>
            row.folder || row.url === '' ? (
              row.url
            ) : (
              <a href={addAttachment(row.url)} target="_blank" rel="noreferrer">
                {row.url}
              </a>
            ),
        },
      ]}
      pagination
      defaultSortFieldId={2}
    />
  );
};

export default Table;
