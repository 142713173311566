import React from 'react';
import { Container as ContainerBootstrap } from 'react-bootstrap';

interface IProps {
  children?: React.ReactNode;
  title?: string;
}

const Container: React.FC<IProps> = ({ children, title }) => (
  <>
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0 text-dark">{title}</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="content">
      <ContainerBootstrap fluid>{children}</ContainerBootstrap>
    </section>
  </>
);

export default Container;
