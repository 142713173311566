export interface IMessage {
  Action: IAction;
  Message: string;
  Key: string;
  Created: string;
}

export enum ILevel {
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
}

export enum IAction {
  Logger = 'logger',
  Notif = 'notif',
  Counter = 'counter',
}
