import React from 'react';

import { Outlet } from 'react-router-dom';

import { Container as BootstrapContainer } from 'react-bootstrap';
import Header from './template/Header';
import Sidebar from './template/Sidebar';
import useResize from './hooks/useResize';
import Footer from './template/Footer';

const Container: React.FC = () => {
  const [minHeight] = useResize(window.innerHeight - 96, 96);

  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <BootstrapContainer fluid className="content-wrapper" style={{ minHeight }} id="container">
        <Outlet />
      </BootstrapContainer>
      <Footer />
    </div>
  );
};

export default Container;
