import React, { useMemo } from 'react';

import Logo from '../img/logo.png';
import { useSelector } from 'react-redux';
import { selectAuth } from '../features/auth/authSlice';
import { decodeJwt } from '../helpers';
import { ITokenPayload } from '../features/auth/types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Sidebar: React.FC = () => {
  const { token } = useSelector(selectAuth);

  const decode = useMemo(() => decodeJwt<ITokenPayload>(token), [token]);

  return (
    <aside className="main-sidebar elevation-4 sidebar-dark-primary">
      <Link to={'/home'} className="brand-link" role="button">
        <img className="brand-image img-circle elevation-3" src={Logo} alt="SeAT" />
        <span className="brand-text font-weight-light">
          <b>N</b>yu<b>F</b>older
        </span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img src="//images.evetech.net/characters/2116788576/portrait?size=64" className="img-circle elevation-2" alt="User" />
          </div>
          <div className="info">
            <Link to={'/home'} className="d-block" role="button">
              {decode?.username}
            </Link>
          </div>
        </div>
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column nav-child-indent" role="menu">
            <li className="nav-item">
              <Link className="nav-link" style={{ width: '100%' }} to={'/home'}>
                <FontAwesomeIcon icon={faHome} />
                <p>Home</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" style={{ width: '100%' }} to={'/files'}>
                <FontAwesomeIcon icon={faHome} />
                <p>Fichier</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
