export interface IFolder {
  id: number;
  name: string;
  Path: string;
  download_path: string;
  role: number;
}

export enum ITypeFolder {
  Public = 1,
  Private = 2,
}

export interface ICreateFolderRequest {
  name: string;
  type: ITypeFolder;
}
