import React, { useState, useCallback, useLayoutEffect, useEffect, useMemo } from 'react';
import Container from '../../template/Container';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Folders from '../folders/Folders';
import { useGetFilesQuery } from './api';
import { convertTimestamp, humanReadableBytes } from '../../helpers';
import Actions from './modal/Actions';
import { IFile, IFileFiltered } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setIDFile } from '../user/userSlice';
import { AppDispatch } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faGrin } from '@fortawesome/free-solid-svg-icons';

import Table from './Table';
import Grid from './Grid';

const Files: React.FC = () => {
  const { idFile } = useSelector(selectUser);
  const dispatch = useDispatch<AppDispatch>();

  const [id, setID] = useState(idFile);
  const [extraPath, setExtraPath] = useState('');
  const [action, setAction] = useState<IFile>();
  const [search, setSearch] = useState('');
  const [grid, setGrid] = useState(false);

  const { data, isLoading, isFetching, startedTimeStamp, refetch } = useGetFilesQuery({ id_folder: id, extra_path: extraPath }, { skip: id === 0 });

  useLayoutEffect(() => {
    setExtraPath('');
    setSearch('');
  }, [id]);

  useEffect(() => {
    dispatch(setIDFile(id));
  }, [id, dispatch]);

  const back = useCallback(() => {
    const path = extraPath.split('/');
    if (path[path.length - 1] === '') path.pop();
    path.pop();
    setExtraPath(path.join('/'));
  }, [extraPath]);

  const rowsFiltered: IFileFiltered[] = useMemo(() => {
    if (data) {
      const filtered: IFileFiltered[] = data.files.map((x, k) => ({
        index: k,
        folder: x.folder,
        name: x.name,
        size: humanReadableBytes(x.size),
        url: x.url,
      }));

      if (search !== '') return filtered.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
      return filtered;
    }

    return [];
  }, [data, search]);

  const headerComponent = useMemo(
    () => (
      <Col lg={12}>
        <Row>
          <Col lg={2}>
            <Form.Control type="text" placeholder="Rechercher" value={search} onChange={(e) => setSearch(e.target.value)} />
          </Col>
          <Col lg={2}>
            <Button>
              <FontAwesomeIcon onClick={() => setGrid(!grid)} role="button" icon={faGrin} />
            </Button>
          </Col>
        </Row>
      </Col>
    ),
    [search, grid],
  );

  return (
    <Container title="Explorateur de fichier">
      {action && <Actions extraPath={extraPath} row={action} currentIDFolder={id} handleClose={() => setAction(undefined)} />}
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Folders
                onClick={(id) => {
                  back();
                  setID(id);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="card-gray card-outline">
            <Card.Header>
              <Card.Title>Explorateur</Card.Title>
              {id !== 0 && (
                <Col md={3} className="ms-auto text-right">
                  <span className="mr-3">{convertTimestamp(startedTimeStamp || 0)}</span>
                  <Button disabled={isLoading || isFetching} variant={isLoading || isFetching ? 'warning' : 'primary'} onClick={refetch}>
                    <FontAwesomeIcon spin={isLoading || isFetching} icon={faRotateRight} />
                  </Button>
                </Col>
              )}
            </Card.Header>
            <Card.Body>
              {grid ? (
                <Grid
                  files={rowsFiltered}
                  isBusy={isLoading || isFetching}
                  onClickFolder={(filename) => setExtraPath(extraPath + filename + '/')}
                  onClickFile={(file) => data && setAction(data.files[file.index])}
                  headerComponent={headerComponent}
                />
              ) : (
                <Table
                  files={rowsFiltered}
                  isBusy={isLoading || isFetching}
                  onClickFolder={(filename) => setExtraPath(extraPath + filename + '/')}
                  onClickFile={(file) => data && setAction(data.files[file.index])}
                  headerComponent={headerComponent}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Files;
