import React, { useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import Container from './Container';
import ContainerLogin from './ContainerLogin';
import Auth from './features/auth/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from './features/auth/authSlice';
import Home from './Home';
import Files from './features/files/Files';
import Streaming from './features/streaming/Streaming';
import { AppDispatch } from './store';
import { wsconnect } from './socket';
import Console from './features/socket/Console';
import { urlSocket } from './api';

function App() {
  const { token } = useSelector(selectAuth);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (token) dispatch(wsconnect({ url: urlSocket }));
    return () => {
      dispatch({ type: 'WS_DISCONNECT' });
    };
  }, [dispatch, token]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ContainerLogin />}>
          <Route path="" element={<Navigate to="/login" />} />
          <Route path="login" element={token ? <Navigate to="/home" /> : <Auth />} />
        </Route>
        <Route path="/" element={!token ? <Navigate to="/login" /> : <Container />}>
          <Route path="files" element={<Files />} />
          <Route path="home" element={<Home />} />
          <Route path="streaming" element={<Streaming />} />
          <Route path="console" element={<Console />} />
          <Route path="" element={<Navigate to="/login" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
