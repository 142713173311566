import React from 'react';
import { Spinner as SpinnerBootstrap } from 'react-bootstrap';

const Spinner: React.FC = () => (
  <div className="spinner absolute d-flex justify-content-center align-items-center" style={{ zIndex: 1000 }}>
    <SpinnerBootstrap animation="border" role="status">
      <span className="sr-only">Chargement...</span>
    </SpinnerBootstrap>
  </div>
);

export default Spinner;
