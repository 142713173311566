import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import { filesApi } from '../files/api';

const initialState = {
  token: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    logout(state) {
      state.token = '';
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(filesApi.endpoints.GetFiles.matchRejected, (state) => {
      state.token = '';
    });
  },
});

export const { setToken, logout } = authSlice.actions;

export const name = authSlice.name;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
