import React, { useState } from 'react';
import { useGetFoldersQuery } from './api';
import Spinner from '../../Spinner';
import { Link } from 'react-router-dom';
import Create from './Create';

interface IProps {
  onClick: (folder: number) => void;
}

const Folders: React.FC<IProps> = ({ onClick }) => {
  const { data, isLoading, isFetching } = useGetFoldersQuery(null);
  const [create, setCreate] = useState(false);

  if (isLoading || isFetching || !data) return <Spinner />;

  return (
    <div className="pb-3">
      {create && <Create handleClose={() => setCreate(false)} />}
      <ul className="nav nav-pills">
        {data.map((x, k) => (
          <li key={k} role="presentation" className="nav-item">
            <Link to={`#`} onClick={() => onClick(x.id)} className="nav-link">
              {x.name}
            </Link>
          </li>
        ))}
        <li role="presentation" className="nav-item">
          <Link to={`#`} onClick={() => setCreate(true)} className="nav-link">
            +
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Folders;
