import React from 'react';

import { Outlet } from 'react-router-dom';

const ContainerLogin: React.FC = () => {
  return (
    <div className="login-page" style={{ minHeight: 456 }}>
      <Outlet />
    </div>
  );
};

export default ContainerLogin;
