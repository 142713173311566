import React, { useCallback, useState } from 'react';

import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import Move from './Move';
import { useDeleteFileMutation, useMoveFileMutation, useMp4FileMutation, useZipFileMutation } from '../api';

import Swal from 'sweetalert2';
import { IFile } from '../types';
import Spinner from '../../../Spinner';
import { useNavigate } from 'react-router-dom';
import { streaming } from '../config';

interface IProps {
  handleClose: () => void;
  currentIDFolder: number;
  extraPath: string;
  row: IFile;
}

enum IForm {
  nothing = 0,
  move = 1,
  delete = 2,
}

const success = (title: string, text: string) => Swal.fire({ icon: 'success', title, text });
const error = (title: string, text: string) => Swal.fire({ icon: 'error', title, text });

const Actions: React.FC<IProps> = ({ handleClose, currentIDFolder, extraPath, row }) => {
  const [form, setForm] = useState<IForm>(IForm.nothing);
  const navigate = useNavigate();
  const [move, { isLoading }] = useMoveFileMutation();
  const [del, { isLoading: isLoadingDelete }] = useDeleteFileMutation();
  const [zip, { isLoading: isLoadingZip }] = useZipFileMutation();
  const [mp4, { isLoading: isLoadingMp4 }] = useMp4FileMutation();

  const submit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formElements = e.currentTarget.elements;

      if (form === IForm.move) {
        const from = (formElements.namedItem('from') as HTMLSelectElement).value;
        const to = (formElements.namedItem('to') as HTMLSelectElement).value;
        const copy = (formElements.namedItem('copy') as HTMLInputElement).checked;

        await move({ from_id_folder: Number(from), to_id_folder: Number(to), file: extraPath + row.name, copy })
          .then(() => {
            success('Déplacement', 'Le fichier à été déplacer');
            handleClose();
          })
          .catch(() => error('Déplacement', 'Une erreur est survenu'));
      }
    },
    [form, move, row.name, extraPath, handleClose],
  );

  const deleteFile = useCallback(() => {
    setForm(IForm.nothing);
    Swal.fire({
      title: 'Voulez-vous supprimer ce fichier ?',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        del({ id_folder: currentIDFolder, file: extraPath + row.name })
          .then(() => {
            handleClose();
            success('Suppression', 'Le fichier à été supprimer');
          })
          .catch(() => error('Suppression', 'Une erreur est survenu'));
      }
    });
  }, [del, currentIDFolder, row, extraPath, handleClose]);

  const zipFile = useCallback(() => {
    setForm(IForm.nothing);
    Swal.fire({
      title: 'Voulez-vous compresser ce fichier ?',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        zip({ id_folder: currentIDFolder, file: extraPath + row.name })
          .then(() => {
            success('Zip', 'Le fichier à été compressé');
            handleClose();
          })
          .catch(() => error('Zip', 'Une erreur est survenu'));
      }
    });
  }, [zip, currentIDFolder, row, extraPath, handleClose]);

  const mp4File = useCallback(() => {
    setForm(IForm.nothing);
    Swal.fire({
      title: 'Voulez-vous passer ce fichier en mp4 ?',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        mp4({ id_folder: currentIDFolder, file: extraPath + row.name })
          .then(() => {
            success('Mp4', 'Le fichier à été convertie en mp4');
            handleClose();
          })
          .catch(() => error('Mp4', 'Une erreur est survenu'));
      }
    });
  }, [mp4, currentIDFolder, row, extraPath, handleClose]);

  return (
    <Modal show={true} onHide={handleClose}>
      {isLoading || isLoadingDelete || isLoadingZip || isLoadingMp4 ? <Spinner /> : null}
      <Modal.Header closeButton>
        <Modal.Title>Actions {row.folder ? 'Dossier' : 'Fichier'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submit}>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col className="mt-3">
              <Button onClick={() => setForm(IForm.move)}>Déplacer</Button>
            </Col>
            <Col className="mt-3">
              <Button onClick={deleteFile}>Supprimer</Button>
            </Col>
            {row.name.indexOf('.zip') === -1 && (
              <Col className="mt-3">
                <Button onClick={zipFile}>Compresser</Button>
              </Col>
            )}
            {row.name.indexOf('.mp4') === -1 && !row.folder && (
              <Col className="mt-3">
                <Button onClick={mp4File}>Mp4</Button>
              </Col>
            )}
            {row.name.indexOf('.mp4') !== -1 && !row.folder && row.streaming !== '' && (
              <Col className="mt-3">
                <Button onClick={() => navigate('/streaming', { state: { url: `${row.streaming}?api_key=${streaming}` } })}>Streaming</Button>
              </Col>
            )}
          </Row>

          <Row className="mt-3">
            <Col>{form === IForm.move && <Move currentIDFolder={currentIDFolder} />}</Col>
          </Row>
        </Modal.Body>
        {form === IForm.move && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit">Valider</Button>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
};

export default Actions;
