import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { IFileFiltered } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { addAttachment } from './helpers';

interface IProps {
  files: IFileFiltered[];
  isBusy: boolean;
  onClickFile?: (row: IFileFiltered) => void;
  onClickFolder?: (filename: string) => void;
  headerComponent: React.ReactNode;
}

const Grid: React.FC<IProps> = ({ files = [], isBusy, onClickFile, onClickFolder, headerComponent }) => {
  return (
    <Row>
      {headerComponent}
      <Col md={12} className="mt-3">
        <Row>
          {isBusy ? (
            <FontAwesomeIcon size="10x" spin icon={faSpinner} />
          ) : (
            files.map((x, k) => (
              <Col key={k} md={4}>
                <Card
                  className="text-break"
                  role="button"
                  onClick={() => {
                    if (!x.folder && onClickFile) return onClickFile(files[x.index]);
                    onClickFolder && onClickFolder(x.name);
                  }}
                >
                  <Card.Header>
                    <Card.Title>
                      <FontAwesomeIcon icon={x.folder ? faFolder : faFile} /> {!x.folder && x.size}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    {x.folder || x.url === '' ? (
                      x.name
                    ) : (
                      <a href={addAttachment(x.url)} target="_blank" rel="noreferrer">
                        {x.name}
                      </a>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Grid;
